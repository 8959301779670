import axios from 'axios';

const $cartIndicator = document.querySelector('[data-cart-indicator]');
const classes = {
    smallFont: 'navigation-actions__item--cart--small-font',
    visible: 'navigation-actions__item--cart--visible',
};

function updateCartIndicator(itemCount) {
    if (itemCount >= 99) {
        $cartIndicator.setAttribute('data-count', '99+');
        $cartIndicator.classList.add(classes.smallFont);
    } else {
        $cartIndicator.setAttribute('data-count', itemCount);
        $cartIndicator.classList.remove(classes.smallFont);
    }
}

function removeCartIndictor() {
    $cartIndicator.classList.remove(classes.visible);
}

function showCartIndicator() {
    $cartIndicator.classList.add(classes.visible);
}

function getLatestCartData() {
    axios
        .get('/cart.js')
        .then(response => {
            const itemCount = response.data.item_count;

            if (itemCount === 0) {
                removeCartIndictor();
            } else {
                updateCartIndicator(itemCount);
                showCartIndicator();
            }
        })
        .catch(error => {
            console.log(error);
            removeCartIndictor();
        });
}

function init() {
    getLatestCartData();
}

if ($cartIndicator) {
    init();
    window.addEventListener('cart.added', () => {
        getLatestCartData();
    });
}
